.video-container {
  position: relative;
  height: 0;
  overflow: hidden;
}

// 16x9 Aspect Ratio
.video-container-16x9 {
  padding-bottom: 56.25%;
}

.video-container-preview-large {
  padding-bottom: 25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
