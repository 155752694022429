footer {
  margin-bottom: .5rem;
  ul {
    display: flex;
    justify-content: center;
    li {
      list-style: none;
      display: flex;
      align-items: center;
      margin-right: 1rem;
      @include breakpoint (large up) {
        margin-right: 3rem;
      }
      &:last-child {
        margin-right: 0;
      }
      .linkedin-icon {
        height: 25px;
        @include breakpoint (medium up) {
          height: 50px;
        }
      }
      .xing-icon {
        height: 25px;
        @include breakpoint (medium up) {
          height: 50px;
        }
      }
      .download-icon {
        height: 25px;
        @include breakpoint (medium up) {
          height: 50px;
        }
      }
    }
  }
  .footer-menu {
    @include breakpoint (large up) {
      margin-top: 2rem;
    }
    li {
      a {
        font-size: rem-calc(14);
        display: flex;
      }
    }
  }
}
