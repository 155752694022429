.cookie-disclaimer {
  position: fixed;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;
  color: white;
  background-color: rgba($primary-color,0.8);
  padding: 1rem;
}

.cookie-disclaimer__text {
  a {
    color: white;
    text-decoration: underline;
  }
}

.cookie-disclaimer__button {
  color: $body-font-color;
  padding: 0.5rem;
  cursor: pointer;
  background-color: $white;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  text-align: center;
  display: block;
  @include breakpoint (medium up) {
    display: inline-block;
    float: right;
  }
}
