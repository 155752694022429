// sass-lint:disable nesting-depth, no-ids

header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0 2rem;
  width: 100%;
  @include breakpoint (medium up) {
    padding: 2rem 0 4rem;
    flex-direction: row;
    justify-content: space-between;
  }
  .logo {
    display: flex;
    align-self: center;
    margin-bottom: 1rem;
    @include breakpoint (medium up) {
      margin-bottom: 0;
    }
    img {
      width: 400px;
    }
  }
  .menu-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .language-menu {
      display: flex;
      margin: 0;
      height: 30px;
      @include breakpoint (medium up) {
        margin-right: 2rem;
        height: 42px;
      }
      li {
        display: flex;
        a {
          display: block;
          padding: 0 .5rem;
          border: 1px solid $primary-color;
          @include breakpoint (medium up) {
            padding: .3rem .5rem;
          }
          &:hover {
            background-color: $primary-color;
            color: $white;
          }
        }
        &:first-child a {
          border-right: none;
        }
      }
      .active a {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
}

.menu-icon {
  width: 35px;
  height: 25px;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: $black;
    box-shadow: 0 10px 0 $black, 0 20px 0 $black;
    content: " ";
  }
  &:hover::after {
    box-shadow: 0 10px 0 $medium-gray, 0 20px 0 $medium-gray;
  }
}

.open-menu-checkbox {
  display: none;
}

.open-menu-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($black, .9);
  z-index: 999;
  label {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
    top: 15px;
    background: url("../img/icons/close.svg");
    z-index: 100;
    cursor: pointer;
    @include breakpoint (medium up) {
      width: 40px;
      height: 40px;
      top: 35px;
    }
  }
  nav {
    text-align: center;
    position: relative;
    top: 52%;
    left: 50%;
    height: 85%;
    width: 85%;
    font-size: 1.2rem;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $body-font-color;
    color: $white;
    font-weight: 400;
    border: 2px solid $white;
    @include breakpoint (medium up) {
      top: 50%;
      font-size: 3rem;
    }
    &::after,
    &::before {
      content: " ";
      position: absolute;
      background: rgba($black, 1);
    }
    &::before {
      width: calc(100% + 50px + 6px - 200px);
      height: calc(100% + 6px);
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    &::after {
      height: calc(100% + 50px + 6px - 200px);
      width: calc(100% + 6px);
      left: -3px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 0;
      z-index: 999;
      li {
        display: flex;
        a {
          font-weight: 600;
          display: flex;
          color: $white;
          text-decoration: none;
          -webkit-transition: color .2s;
          transition: color .2s;
          text-transform: uppercase;
          &:focus,
          &:hover {
            color: $yellow;
          }
        }
      }
    }
  }
}

.nav-container {
  flex-direction: column;
  &::before {
    border-radius: 50%;
    width: 30%;
    padding-bottom: 30%;
    border: 1px solid rgba($white, .4);
    background: transparent;
    content: " ";
    position: absolute;
    z-index: -8;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.lower ~ .overlay {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s, visibility 0s .5s;
  transition: opacity .5s, visibility 0s .5s;
}

#open-menu:checked ~ .overlay {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}

.overlay nav ul {
  opacity: .4;
  -webkit-transform: translateY(-25%) rotateX(35deg);
  transform: translateY(-25%) rotateX(35deg);
  -webkit-transition: -webkit-transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s;
}

#open-menu:checked ~ .overlay nav ul {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
}

#open-menu:not(:checked) ~ .overlay nav ul {
  -webkit-transform: translateY(25%) rotateX(-35deg);
  transform: translateY(25%) rotateX(-35deg);
}

.social-media-menu {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  a img {
    height: 25px;
    @include breakpoint (medium up) {
      height: 40px;
    }
  }
  a:first-child {
    margin-right: 2rem;
  }
}
