// sass-lint:disable no-qualifying-elements

$yellow: #ffec00;

h1 {
  display: flex;
  align-items: baseline;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  padding-bottom: 1rem;
}

h3 {
  text-transform: uppercase;
}

.back-to-overview {
  display: flex;
  padding-left: 1rem;
  background: url("../img/icons/arrow-prev.svg");
  background-position: 0 4px;
  width: 20px;
  background-size: 10px;
  background-repeat: no-repeat;
  font-size: .9rem;
  @include breakpoint (medium up) {
    margin-bottom: 2rem;
  }
}

.content-container {
  img {
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
    padding: 0 .5rem;
    &:hover {
      background-color: $black;
      color: $white;
    }
  }
}

.button-special {
  display: flex;
  justify-content: center;
  background-color: $body-font-color;
  color: $white;
  padding: 1.2rem 1.5rem;
  font-weight: 400;
  border: 1px solid $body-font-color;
  position: relative;
  &:hover {
    background-color: $white;
    color: $body-font-color;
    &::after,
    &::before {
      content: " ";
      position: absolute;
      background: $white;
    }
    &::before {
      width: calc(100% + 50px + 2px - 100px);
      height: calc(100% + 2px);
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }
    &::after {
      height: calc(100% + 50px + 2px - 100px);
      width: calc(100% + 2px);
      left: -1px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
    }
    span {
      z-index: 9999;
      display: flex;
      color: $body-font-color;
    }
  }
}

.project-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
}

.contact-container {
  padding: 1rem 0;
  @include breakpoint (medium up) {
    padding: 3rem 0;
  }
  h3 {
    font-weight: 600;
    text-align: center;
  }
  .contact-buttons {
    margin-top: 2rem;
    .button-special {
      margin-bottom: 1rem;
    }
  }
}

.back-to-top {
  display: none;
  position: fixed;
  bottom: 60px;
  right: 35px;
}

.download-document {
  display: table;
  float: right;
  &::before {
    display: table-cell;
    content: "";
    background-image: url("../img/icons/document-download.svg");
    vertical-align: middle;
    background-repeat: no-repeat;
    background-size: 30px;
    width: 30px;
    height: 30px;
  }
}

.link-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2rem;
  a {
    font-size: .8rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    @include breakpoint (medium up) {
      font-size: 1rem;
    }
    img {
      height: 40px;
      margin-bottom: .5rem;
    }
    &:hover {
      background-color: transparent;
      color: $body-font-color;
    }
  }
}

.cookie-disclaimer {
  z-index: 5;
}
