// sass-lint:disable no-qualifying-elements
.category-box {
  margin-bottom: 1rem;
  position: relative;
}

.category-box figure {
  position: relative;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
}

.category-box figure img,
.category-box img {
  width: 100%;
  height: 175px;
  object-fit: cover;
  font-family: "object-fit: cover;";
  @include breakpoint (medium up) {
    height: 350px;
  }
  @include breakpoint (large up) {
    height: 425px;
  }
}

.category-box figure video {
  @include breakpoint (large up) {
    width: 100%;
    height: auto;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }
}

.start-page-video {
  @include breakpoint (large up) {
    overflow: hidden;
    height: 425px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.category-box figure figcaption {
  padding: 2em;
  color: $white;
  text-transform: uppercase;
  font-size: 1.25em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.category-box figure figcaption::before,
.category-box figure figcaption::after {
  pointer-events: none;
}

.category-box figure figcaption,
.category-box-headline,
.category-box figure figcaption > a,
.category-box-headline > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.category-box-headline h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 2rem;
  padding: 2rem;
  text-transform: uppercase;
  text-align: center;
  color: transparent;
  text-shadow: 0 0 3px $white;
  span {
    font-weight: 600;
  }
}

.blurry-text {
  text-shadow: 0 0 300px $white;
  color: transparent;
}

.category-box-content:hover .blurry-text {
  position: relative;
  display: flex;
  justify-content: center;
  height: calc(100% - 2rem);
  border: 2px solid $white;
  box-sizing: border-box;
  padding: 1rem;
  margin: 1rem;
  color: $white;
  text-shadow: none;
  &::before,
  &::after {
    content: " ";
    position: absolute;
    background: $white;
  }
}

.category-box-content:hover img {
  z-index: 99;
}

video {
  margin-bottom: -.5rem;
  margin-left: -3px;
  margin-top: -3px;
}

.special {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100% - 1rem);
  border: 2px solid $body-font-color;
  margin: 0 auto 50px;
  padding: 25px;
  &::before,
  &::after {
    content: " ";
    position: absolute;
    background: $white;
  }
  &::before {
    width: calc(100% + 50px + 4px - 120px);
    height: calc(100% + 4px);
    top: -2px;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    height: calc(100% + 50px + 4px - 120px);
    width: calc(100% + 4px);
    left: -2px;
    top: 50%;
    transform: translateY(-50%);
  }
  .vertical-headline {
    display: flex;
    align-items: center;
    z-index: 1;
    h1 {
      display: flex;
      align-items: baseline;
      color: $body-font-color;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 2.6rem;
      padding-bottom: 0;
      padding: .5rem 0;
    }
  }
}

h1 {
  display: inline-block;
  &::after {
    content: " ";
    height: 8px;
    width: 8px;
    background-color: $yellow;
    border-radius: 50%;
    display: inline-block;
    @include breakpoint (medium up) {
      height: 12px;
      width: 12px;
    }
  }
}
