// sass-lint:disable no-important

.image-description {
  margin-top: 1rem;
}

.prev {
  cursor: pointer;
  &::after {
    content: "";
    background-image: url("../img/icons/arrow-prev.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 35px;
    display: block;
    background-size: 20px;
    z-index: 999;
    @include breakpoint (medium up) {
      width: 30px;
      height: 52px;
      background-size: 30px;
    }
  }
}

.next {
  cursor: pointer;
  &::after {
    content: "";
    background-image: url("../img/icons/arrow-next.svg");
    background-repeat: no-repeat;
    width: 25px;
    height: 35px;
    display: block;
    background-size: 20px;
    z-index: 999;
    @include breakpoint (medium up) {
      width: 30px;
      height: 52px;
      background-size: 30px;
    }
  }
}


.slick-dots li button::before {
  font-size: 12px !important;
}

.slick-dots {
  bottom: -37px !important;
}

.slider-action-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 70%;
  @include breakpoint (large up) {
    height: 80%;
  }
}

.slider-counter-container {
  display: flex;
  flex-direction: column;
  font-weight: 600;
  .current {
    font-size: 5rem;
    color: $body-font-color;
    line-height: 5rem;
  }
  .total {
    font-size: 8rem;
    color: $light-gray;
    line-height: 7rem;
  }
}

.slider-arrow-container {
  display: flex;
  justify-content: flex-end;
  button:first-child {
    margin-right: 1rem;
  }
  @include breakpoint (medium up) {
    margin-top: 3rem;
    justify-content: start;
    margin-bottom: 0;
    button:first-child {
      margin-right: 3rem;
    }
  }
}
